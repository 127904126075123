import { timeFormat } from 'd3-time-format';
import React, { Fragment } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIsFetching } from 'react-query';
import 'twin.macro';

import { useLegendSheet } from '../sheets/useLegendSheet';
import { useSheet } from '../sheets/useSheet';
import { FormValues } from './Settings';
import { ValidationMessage } from './ValidationMessage';
import { validateActivityDates, validateActivityValues, validateBodyMeasurements, validateLegend } from './validators';

interface ValidationsProps {
  form: UseFormReturn<FormValues>;
}

const ValidationTitle = ({ isFetching }: { isFetching: boolean }) => (
  <h3 tw="text-lg font-medium mb-4">
    Sheet validation{' '}
    {isFetching ? (
      <span tw="relative text-sm">
        🔵
        <span tw="absolute left-0 top-0 text-sm animate-ping">🔵</span>
      </span>
    ) : null}
  </h3>
);

export const Validations = ({ form }: ValidationsProps) => {
  const isFetching = !!useIsFetching({ stale: false });
  const pending = !!useIsFetching({ stale: true });

  const { sheetId, selectedSheetId, activityRange, legendRange, bodyRange } = form.watch();
  const spreadsheetId = selectedSheetId || sheetId;

  const formErrors = Object.entries(form.formState.errors);

  const { data, error } = useSheet({
    spreadsheetId,
    range: activityRange,
  });
  const { data: legendData, error: legendError } = useLegendSheet(spreadsheetId, legendRange);
  const { data: bodyData, error: bodyError } = useSheet({
    spreadsheetId,
    range: bodyRange,
  });
  const dates = data ? data[0].slice(1) : [];

  if (formErrors.length) {
    return (
      <div tw="leading-loose">
        <ValidationTitle isFetching={isFetching} />
        {formErrors.map(([key, e]) => (
          <ValidationMessage error={true}>
            {e.type === 'required'
              ? e.message
              : e.type === 'pattern'
              ? `The ${key} field should have A1 notation`
              : `The ${key} field has validation errors`}
          </ValidationMessage>
        ))}
      </div>
    );
  } else if (error) {
    return (
      <div tw="leading-loose">
        <ValidationTitle isFetching={isFetching} />
        <ValidationMessage error={true}>{error.message}</ValidationMessage>
      </div>
    );
  } else if (pending && !data) {
    return (
      <div tw="leading-loose">
        <ValidationTitle isFetching={isFetching} />
        Loading&hellip;
      </div>
    );
  }

  const legendValid = validateLegend(legendRange, legendData);
  const { invalidDates, dateRange } = validateActivityDates(activityRange, data);
  const invalidValues = validateActivityValues(activityRange, data, legendData);
  const invalidBodyValues = validateBodyMeasurements(bodyRange, bodyData);

  return (
    <div tw="leading-loose">
      <ValidationTitle isFetching={isFetching} />

      <ValidationMessage error={false}>Valid sheet ID and activity range</ValidationMessage>
      <ValidationMessage error={!dates.length || !!invalidDates.length}>
        {dates.length ? (
          invalidDates.length ? (
            <>
              Invalid dates:
              <ul tw="ml-7 leading-snug">
                {invalidDates.map(d => (
                  <li key={d.position}>
                    {d.position}: "{d.value}"
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <>
              Found date range <span tw="font-medium">{timeFormat('%d %b %Y')(new Date(dateRange[0]))}</span> until{' '}
              <span tw="font-medium">{timeFormat('%d %b %Y')(new Date(dateRange[1]))}</span>
            </>
          )
        ) : (
          'Please fill in ISO dates on the first row'
        )}
      </ValidationMessage>
      <ValidationMessage error={!!legendError || !legendValid}>
        {legendError ? (
          'Invalid legend range'
        ) : legendValid ? (
          <>
            Found legend:{' '}
            <dl
              tw="grid mt-2"
              css={{
                gridTemplateColumns: '1.5em auto',
                columnGap: '1em',
                rowGap: '0.5em',
              }}
            >
              {legendData.map((v, i) => (
                <Fragment key={i}>
                  <dt
                    css={{
                      backgroundColor: v.color,
                      textIndent: 0,
                      textAlign: 'center',
                      borderRadius: 4,
                      textShadow: '1px -1px rgba(255,255,255,0.75)',
                    }}
                  >
                    {v.key}
                  </dt>
                  <dd css={{ textIndent: 0 }}>{v.label}</dd>
                </Fragment>
              ))}
            </dl>
          </>
        ) : (
          <>
            Legend range should span exactly three rows.
            <br />
            First row contains labels, second row contains key, third row contains formatted cell with key.
          </>
        )}
      </ValidationMessage>
      <ValidationMessage error={!!invalidValues.length || !legendData}>
        {invalidValues.length ? (
          <>
            Invalid values:
            <ul tw="ml-7 leading-snug">
              {invalidValues.map(d => (
                <li key={d.position}>
                  {d.position}: "{d.value}"
                </li>
              ))}
            </ul>
          </>
        ) : legendData ? (
          'All activity values are valid'
        ) : (
          'Could not validate activity values if there is no legend data'
        )}
      </ValidationMessage>

      <ValidationMessage error={!!bodyError} tw="mt-6">
        {bodyError
          ? 'Invalid body measurements range'
          : bodyRange
          ? 'Valid body measurements range'
          : 'No body measurements range set'}
      </ValidationMessage>
      {!bodyError && bodyRange && (
        <>
          <ValidationMessage error={!!invalidBodyValues.length}>
            {invalidBodyValues.length ? (
              <>
                Invalid body measurement values:
                <ul tw="ml-7 leading-snug">
                  {invalidBodyValues.map(d => (
                    <li key={d.value}>
                      {d.position}: "{d.value}"
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              'All body measurement values are valid'
            )}
          </ValidationMessage>
        </>
      )}
    </div>
  );
};
