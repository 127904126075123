import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import 'twin.macro';

import { Button } from '../Button';
import { useAuth } from '../useAuth';
import { Input } from './Input';
import { SelectSheet } from './SelectSheet';
import { FormValues } from './Settings';
import { SignIn } from './SignIn';

interface SettingsFormProps {
  form: UseFormReturn<FormValues>;
  onSubmit: (data: FormValues) => void;
}

export const SettingsForm = ({ form, onSubmit }: SettingsFormProps) => {
  const {
    formState: { touchedFields, errors },
    handleSubmit,
    register,
    watch,
  } = form;

  const { error: authError, signedIn, signingIn, signIn, signOut } = useAuth();
  const selectedSheetId = watch('selectedSheetId');

  return (
    <form onSubmit={handleSubmit(onSubmit)} tw="p-3 bg-gray-200">
      {authError ? (
        <div tw="mb-2">Google Auth Error: {authError.details}</div>
      ) : signingIn ? (
        <div tw="mb-2">Checking Google Sheets authorization&hellip;</div>
      ) : signedIn ? (
        <SelectSheet
          register={register}
          onSignOut={() => {
            form.setValue('selectedSheetId', undefined);
            signOut();
          }}
        />
      ) : (
        <SignIn tw="mb-6" onClick={() => signIn()} />
      )}
      <div tw="grid gap-4 mb-8" css={{ alignItems: 'center', gridTemplateColumns: '220px 1fr' }}>
        <label htmlFor="sheet" tw="font-bold after:(text-red-500 content['*'])">
          Sheet ID
        </label>
        <Input
          disabled={!!selectedSheetId}
          error={!!errors.sheetId}
          name="sheetId"
          required
          touched={touchedFields.sheetId}
          {...register('sheetId', {
            required: 'A sheetID is required. It is the last part of the share URL',
          })}
        />

        <label htmlFor="activityRange" tw="font-bold after:(text-red-500 content['*'])">
          Activity range
        </label>
        <Input
          touched={touchedFields.activityRange}
          error={!!errors.activityRange}
          name="activityRange"
          required
          {...register('activityRange', {
            required: 'Activity range is required. Set a range including column header (dates) and row header (time)',
            pattern: /([A-Z]*)([0-9]+):([A-Z]*)([0-9]+)/g,
          })}
        />

        <label htmlFor="legendRange" tw="font-bold after:(text-red-500 content['*'])">
          Activity legend range
        </label>
        <Input
          touched={touchedFields.legendRange}
          error={!!errors.legendRange}
          name="legendRange"
          required
          {...register('legendRange', {
            required: 'Fill in a legend range spanning two rows, eg. B5:G6',
            pattern: /([A-Z]*)([0-9]+):([A-Z]*)([0-9]+)/g,
          })}
        />

        <label htmlFor="bodyRange" tw="font-bold">
          Body measurements range
        </label>
        <Input
          touched={touchedFields.bodyRange}
          error={!!errors.bodyRange}
          name="bodyRange"
          {...register('bodyRange', {
            pattern: /([A-Z]*)([0-9]+):([A-Z]*)([0-9]+)/g,
          })}
        />

        <label htmlFor="name" tw="font-bold">
          Baby name
        </label>
        <Input touched={touchedFields.name} error={!!errors.name} name="name" {...register('name')} />

        <label htmlFor="name" tw="font-bold leading-snug">
          Birth date
          <br />
          <span tw="text-xs font-normal">
            Required for WHO growth charts. If unset, the first activity data point is used.
          </span>
        </label>
        <Input
          touched={touchedFields.birthDate}
          error={!!errors.birthDate}
          name="birthDate"
          {...register('birthDate')}
          type="date"
        />

        <label htmlFor="name" tw="font-bold leading-snug">
          Gender
          <br />
          <span tw="text-xs font-normal">Required for WHO growth charts.</span>
        </label>
        <div>
          <label tw="mr-4">
            <Input
              touched={touchedFields.gender}
              error={!!errors.gender}
              {...register('gender')}
              name="gender"
              type="radio"
              value="boy"
              tw="mr-2"
            />
            Boy
          </label>
          <label>
            <Input
              touched={touchedFields.gender}
              error={!!errors.gender}
              {...register('gender')}
              name="gender"
              type="radio"
              value="girl"
              tw="mr-2"
            />
            Girl
          </label>
        </div>
      </div>
      <div tw="flex justify-between items-end">
        <Button type="submit">Save</Button>
        <span tw="text-xs">
          <em tw="text-red-500">*</em> Required field
        </span>
      </div>
    </form>
  );
};
