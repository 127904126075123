import React from 'react';
import 'twin.macro';
import { Button } from '../Button';

import { useSheetList } from '../sheets/useSheetList';

export const SelectSheet = ({ onSignOut, register }) => {
  const { data, isLoading, error } = useSheetList();

  if (isLoading) {
    return <span>Loading sheets&hellip;</span>;
  }

  return (
    <div tw="grid gap-4 mb-4" css={{ alignItems: 'center', gridTemplateColumns: '220px 1fr' }}>
      <div>
        <label htmlFor="sheet" tw="font-bold after:(text-red-500 content['*'])">
          Sheet from Drive
        </label>
        <Button type="button" onClick={onSignOut} size="small" tw="ml-2">
          Sign out
        </Button>
      </div>
      <select {...register('selectedSheetId')} tw="border px-2 py-1 rounded">
        <option value="">- Fill in sheet ID manually...</option>
        {data.map(d => (
          <option key={d.id} value={d.id}>
            {d.name}
          </option>
        ))}
      </select>
    </div>
  );
};
