import { extent, max } from 'd3-array';
import { Legend } from '../sheets/useLegendSheet';

import { activities } from '../utils';

type InvalidValue = { value: string; position: string };

export const isValidDate = (d: string) => {
  const date = new Date(d);
  return date.getTime() == date.getTime();
};

export const numberToCol = (i: number): string => {
  const div = Math.floor(i / 26);
  return `${div ? numberToCol(div - 1) : ''}${String.fromCharCode(65 + (i % 26))}`;
};

export const validateLegend = (range: string, data?: any[]): boolean => {
  if (!data) {
    return false;
  }

  let matches;
  const r = /([A-Z]*)([0-9]+):([A-Z]*)([0-9]+)/g;
  if ((matches = r.exec(range)) !== null) {
    const rowStart = matches[2] || 0;
    const rowEnd = matches[4] || 9999;
    return rowEnd - rowStart === 2;
  }
  return false;
};

export const validateActivityDates = (
  range,
  data,
): {
  invalidDates: { value: string; position: string }[];
  dateRange?: string[];
} => {
  let invalidDates = [];
  let dateRange;
  let dates;
  const expectedNumberOfDates = data ? max(data, d => d.length) - 1 : 0;
  if (data) {
    dates = data[0].slice(1);
    dates = dates.concat(Array(expectedNumberOfDates - dates.length).fill(''));
  } else {
    dates = [];
  }

  let matches;
  const r = /([A-Z]*)([0-9]+):([A-Z]*)([0-9]+)/g;
  if ((matches = r.exec(range)) !== null) {
    const colStart = matches[1] || 'A';
    const rowStart = matches[2] || 0;

    // Validate date columns
    invalidDates = dates.reduce((acc, value, i) => {
      if (!isValidDate(value)) {
        acc.push({
          value,
          position: `${numberToCol(colStart.charCodeAt(0) - 64 + i)}${rowStart}`,
        });
      }
      return acc;
    }, []);

    if (!invalidDates.length) {
      dateRange = extent(dates);
    }
  }

  return { invalidDates, dateRange };
};

export const validateActivityValues = (range: string, data?: string[][], legendData?: Legend[]): InvalidValue[] => {
  if (!data || !legendData) {
    return [];
  }

  let matches;
  const r = /([A-Z]*)([0-9]+):([A-Z]*)([0-9]+)/g;
  if ((matches = r.exec(range)) !== null) {
    const colStart = matches[1] || 'A';
    const rowStart = matches[2] || 0;

    const activities = legendData.map(d => d.key) || [];
    const rows = data.slice(1).map(d => d.slice(1));
    return rows.reduce((acc, row, i) => {
      const invalidValues = row.reduce((invalidInrow, v, j) => {
        if (![...activities, ''].includes(v)) {
          invalidInrow.push({
            value: v,
            position: `${numberToCol(colStart.charCodeAt(0) - 64 + j)}${+rowStart + 1 + i}`,
          });
        }
        return invalidInrow;
      }, [] as InvalidValue[]);
      return acc.concat(...invalidValues);
    }, [] as InvalidValue[]);
  }

  return [];
};

export const validateBodyMeasurements = (range: string, data?: string[][]): InvalidValue[] => {
  if (!data) {
    return [];
  }

  let invalidBodyValues = [];
  let matches;
  const r = /([A-Z]*)([0-9]+):([A-Z]*)([0-9]+)/g;
  if ((matches = r.exec(range)) !== null) {
    const colStart = matches[1] || 'A';
    const rowStart = matches[2] || 0;
    if (data) {
      invalidBodyValues = data.slice(1).reduce((acc, row, i) => {
        const invalidValues = row.reduce((invalidInrow, v, j) => {
          if (v !== '' && (j ? isNaN(+v) : !isValidDate(v))) {
            invalidInrow.push({
              value: v,
              position: `${numberToCol(colStart.charCodeAt(0) - 65 + j)}${+rowStart + 1 + i}`,
            });
          }
          return invalidInrow;
        }, []);
        return acc.concat(...invalidValues);
      }, []);
    }
  }
  return invalidBodyValues;
};
