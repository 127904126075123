import { useNavigate } from '@reach/router';
import React, { useState, useContext, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Transition } from 'react-transition-group';
import tw from 'twin.macro';

import { ConfigContext } from '../ConfigContext';
import { SettingsForm } from './SettingsForm';
import { Validations } from './Validations';

export interface FormValues {
  selectedSheetId: string;
  sheetId: string;
  activityRange: string;
  legendRange: string;
  bodyRange: string;
  name: string;
  birthDate: string;
  gender: string;
}

const Settings = () => {
  const navigate = useNavigate();
  const config = useContext(ConfigContext);

  const [settingsSaved, setSettingsSaved] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      sheetId: config.spreadsheetId,
      activityRange: config.activityRange,
      legendRange: config.legendRange,
      bodyRange: config.bodyRange,
      name: config.name,
      birthDate: config.birthDate,
      gender: config.gender,
    },
    mode: 'onChange',
  });

  const onSubmit = (data: FormValues) => {
    if (typeof window !== `undefined`) {
      config.setSpreadsheetId(data.selectedSheetId || data.sheetId);
      config.setActivityRange(data.activityRange);
      config.setLegendRange(data.legendRange);
      config.setBodyRange(data.bodyRange);
      config.setName(data.name);
      config.setBirthDate(data.birthDate);
      config.setGender(data.gender);
      setSettingsSaved(true);
      setTimeout(() => setSettingsSaved(false), 3000);
    }
    navigate && navigate('/about', { replace: true });
  };

  return (
    <div tw="px-4 pb-4" css={{ maxWidth: 1440 }}>
      <div css={{ maxWidth: 700 }}>
        <p>
          This website was built when we had sleepless nights with our first son. We wanted to find patterns to see if
          we can improve our situation.
          <br />
          Later we realized that this application could be of use for many tired parents.
        </p>
        <p>
          The visualization that is loaded by default uses randomized data as a source, but if you want to drive it
          using your own data, fill in your <code>sheetId</code> and set the correct range. Select both column headings
          (ISO dates) and row headings (24h time).
        </p>
        <h2 tw="text-xl font-bold mb-4 mt-8">Settings ⚙</h2>
        <p>
          I give you a head start with{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1rFwCQZhEa16xZyIZqIi6b6bWKreJvI4AGBh5Db_ZsjM/edit"
            target="_blank"
          >
            this sheet
          </a>{' '}
          containing some random values.
          <br />
          Save it to your Drive and modify if you want to keep your own log.
        </p>
        <p tw="font-medium border-l-8 pl-3 py-2 border-blue-300">
          👌🏻 Everything runs locally in your browser and the application is not using any database to store settings or
          sheet data. It's your data.
        </p>
      </div>
      <div tw="grid gap-x-4 my-8" css={{ gridTemplateColumns: '700px 1fr' }}>
        <SettingsForm form={form} onSubmit={onSubmit} />
        <Validations form={form} />
      </div>

      <Transition mountOnEnter unmountOnExit timeout={500} in={settingsSaved}>
        {state => (
          <div
            tw="rounded border border-blue-500 bg-blue-100 text-blue-700 px-4 py-3 mb-4"
            css={[
              tw`opacity-0 transition-opacity duration-500`,
              {
                entering: { opacity: 1 },
                entered: { opacity: 1 },
                exiting: { opacity: 0 },
              }[state],
            ]}
          >
            <strong>✅ Settings saved!</strong>
            <p tw="mb-0">These settings are stored in your local browser storage.</p>
          </div>
        )}
      </Transition>

      <div css={{ maxWidth: 700 }}>
        <h2 tw="text-xl font-bold mb-4 mt-8">What is under the hood? 🏗</h2>
        <p>
          This application is built with <a href="https://gatsbyjs.com">Gatsby</a> (only because{' '}
          <a href="https://create-react-app.dev">CRA</a> developer experience sucks) and hosted by{' '}
          <a href="https://netlify.com">Netlify</a>.
        </p>
        <p>
          Charts are constructed using <a href="https://github.com/crossfilter/crossfilter">Crossfilter</a> and{' '}
          <a href="https://www.d3js.org">D3.js</a>, but rendered with <a href="https://reactjs.org">React</a>.<br />
          Data is fetched from <a href="https://developers.google.com/sheets/api/">Google Sheets API</a> with some help
          from <a href="https://react-query.tanstack.com">React Query</a> and{' '}
          <a href="https://react-hook-form.com">React Hook Form</a>.
        </p>
        <p>
          I created the styles using <a href="https://styled-components.com">Styled Components</a>,{' '}
          <a href="https://tailwindcss.com">Tailwind</a> and{' '}
          <a href="https://github.com/ben-rogerson/twin.macro">Twin.macro</a>.
        </p>
        <p>
          Weather data is coming from my own Davis Vantage Pro2 weather station. It is uploading to{' '}
          <a href="https://wow.metoffice.gov.uk">WOW</a> but unfortunately the Met Office API is so badly maintained
          that the{' '}
          <a href="https://mowowprod.portal.azure-api.net/issues/5f442d14b19d220488b48615">
            SSL certificate has expired
          </a>
          . So I cannot retrieve it to feed the weather charts. Therefore I circumvented this API by uploading the raw
          weather data into <a href="https://firebase.google.com/products/firestore">Firestore</a> using a{' '}
          <a href="https://firebase.google.com/products/functions">Cloud Function</a>.
        </p>
        <p>
          Firestore is not very suitable for timebased data, so in 2023 I replaced it with{' '}
          <a href="https://www.influxdata.com/products/influxdb">InfluxDB OSS</a> with{' '}
          <a href="https://www.influxdata.com/time-series-platform/telegraf">Telefgraf</a> running in{' '}
          <a href="https://www.docker.com">Docker</a> on a NAS.
        </p>
        <p>The weather data visible on this website is updated every 5 minutes.</p>
        <p tw="mt-8">
          &copy; 2020-{new Date().getFullYear()} <a href="https://www.frankvanwijk.nl">Frank van Wijk</a>
        </p>
      </div>
    </div>
  );
};

export default Settings;
