import React from 'react';
import 'twin.macro';

export const ValidationMessage = ({ children, error = false, ...props }) => (
  <div
    css={{
      textIndent: '-1.65em',
      paddingLeft: '1.65em',
      lineHeight: '1.25rem',
    }}
    tw="mb-3"
    {...props}
  >
    {error && '❌ '}
    {!error && '✅ '}
    {children}
  </div>
);
