import { useQuery } from 'react-query';
import googleSheetsClient from './googleSheetsClient';

export const useSheetList = (enabled = true) => {
  return useQuery<Pick<gapi.client.drive.File, 'id' | 'name'>[], Error>(
    'drive',
    async () => {
      const client = await googleSheetsClient;
      const files = await client.drive.files.list({
        corpora: 'user',
        fields: 'files(id,name)',
        q: "mimeType='application/vnd.google-apps.spreadsheet' and ('me' in owners or sharedWithMe)",
      });
      return files.result.files;
    },
    { enabled },
  );
};
