import { Link, useLocation } from '@reach/router';
import React from 'react';
import 'twin.macro';

import Layout from '../components/layout';
import Settings from '../components/settings/Settings';
import SEO from '../components/seo';

const SettingsPage = () => {
  const location = useLocation();

  return (
    <Layout>
      <SEO title="About" />
      <h1 tw="text-2xl font-bold px-4">About this site</h1>
      <p tw="mb-2 px-4">
        <Link to={`/${location.search}`} tw="no-underline">
          🏠 <span tw="underline hover:no-underline">Home</span>
        </Link>
      </p>
      <Settings tw="mx-4" />
    </Layout>
  );
};

export default SettingsPage;
