import React from 'react';
import tw from 'twin.macro';

import { Button } from '../Button';

const Icon = ({ name }) => (
  <span
    css={[
      tw`inline-block relative`,
      {
        background: `url(http://ssl.gstatic.com/docs/common/h_sprite54_grey_medium.svg) ${
          { sheets: '0 -261px', drive: '-24px -1260px' }[name]
        }`,
        height: 24,
        width: 24,
        top: 5,
      },
    ]}
  />
);

export const SignIn = ({ onClick, ...props }) => {
  return (
    <div tw="bg-blue-900 text-white p-4" {...props}>
      <p tw="mb-0">
        <Button variant="outlined" inversed onClick={onClick} type="button">
          Sign in
        </Button>{' '}
        to Google Drive and select a sheet or fill in a <em>public</em> Sheet ID below.
      </p>
      <small tw="text-xs text-gray-300">
        Google will request access to <Icon name="drive" /> <strong>Drive</strong> for listing files and{' '}
        <Icon name="sheets" /> <strong>Sheets</strong> for reading a sheet.
      </small>
    </div>
  );
};
