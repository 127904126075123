import React, { forwardRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import tw from 'twin.macro';

import { FormValues } from './Settings';

export const Input = forwardRef<
  HTMLInputElement,
  {
    disabled?: boolean;
    error: boolean;
    name: string;
    required?: boolean;
    touched: boolean;
    type?: string;
    value?: any;
  } & ReturnType<UseFormRegister<FormValues>>
>(({ disabled, error, onChange, onBlur, name, required, touched, type = 'text', value, ...props }, ref) => (
  <input
    disabled={disabled || null}
    onBlur={onBlur}
    onChange={onChange}
    name={name}
    ref={ref}
    required={required}
    type={type}
    value={value}
    tw="border px-2 py-1 rounded"
    css={[
      touched && error && tw`text-red-600 border-red-500 focus:(outline-none border-red-700)`,
      !touched && tw`border-gray-300`,
    ]}
    {...props}
  />
));
